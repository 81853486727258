<template>
    <div class='login'>
        <div class='login_center'>
            <div class='title'>{{$t('resetpassword.title')}}</div>
            <div class="logo"><img src='@/assets/logo.png' /></div>
            <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="Email">
                <a-input
                    v-decorator="[
                        'Email',
                        { rules: [{ required: true, message: $t('register.email_msg') }] },
                    ]"
                    :placeholder="$t('register.email_msg')"
                />
                </a-form-item>

                <a-form-item v-bind="formItemLayout" :label="$t('resetpassword.item_1')">
                <a-row :gutter="8">
                    <a-col :span="10">
                        <a-input
                            v-decorator="[
                            'Code',
                            { rules: [{ required: true, message: $t('resetpassword.item_1_msg') }] },
                            ]"
                        />
                    </a-col>
                    <a-col :span="14">
                        <a-button :loading="loading" @click="get_code">{{$t('resetpassword.getCode')}}<span>{{getCode}}</span></a-button>
                    </a-col>
                </a-row>
                </a-form-item>

                <a-form-item :label="$t('resetpassword.item_2')">
                <a-input
                    v-decorator="[
                    'NewPassword',
                    { rules: [{ required: true, message: $t('resetpassword.item_2_msg') }] },
                    ]"
                    :placeholder="$t('resetpassword.item_2_msg')"
                />
                </a-form-item>

                <a-form-item v-bind="tailFormItemLayout">
                    <a-button type="primary" @click="save">
                        {{$t('form.btn_submit')}}
                    </a-button>
                </a-form-item>

            </a-form>
            <div slot="tolinkclick" @click="toLoginBtn" class="signup">{{$t('resetpassword.item_3')}} <span>{{$t('resetpassword.item_4')}}</span></div>
        </div>
    </div>
</template>

<script>
import{get_code,reset_password} from "@/network/resetpassword";
export default {
    name: 'reset',
    components: {},
    directives: {  },
    data() {
        return {
            flag:true,
            getCode:'',
            loading:false,
            tailFormItemLayout: {
                wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
                },
            },
            formItemLayout: {
                labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                },
                wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                },
            },
            form: this.$form.createForm(this, { name: 'user_from' }),
        };
    },
    created() {

    },
    methods: {
        toLoginBtn(){
            this.$router.replace('/login')
        },
        save(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log(values);
                    var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
                    var isok= reg.test(values.Email);
                    if(isok){
                        // alert('邮箱格式正确！')
                    }else{
                        this.$message.error('Email format error!');
                        return false;
                    }
                    reset_password({email:values.Email,password:values.NewPassword,code:values.Code}).then(res=>{
                        if(res.header.code==200){
                            this.$message.success('success');
                            this.$router.push('/login');
                        }else{
                            this.$message.error('error');
                        }
                    });
                }
            });
        },
        get_code(e){
            let that = this;
            if(that.flag){
                let timeout = 60;
                that.flag = false;
                that.loading = true;
                
                let Email = this.form.getFieldValue('Email');
                var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
                var isok= reg.test(Email);
                if(isok){
                    // alert('邮箱格式正确！')
                }else{
                    this.$message.error('Email format error!');
                    that.loading = false;
                    that.flag = true;
                    return false;
                }
                get_code(Email).then(res=>{
                    // if(res.header.code==200){
                        this.$message.success('success');
                        let timeoutObj = setInterval(function(){
                            if(timeout == 0){
                                that.flag = true;
                                that.getCode = ' ';
                                clearInterval(timeoutObj);
                            }else{
                                timeout--;
                                that.getCode = '('+timeout+'s)';
                            }
                        },1000);
                    // }else{
                    //     that.flag = true;
                    // }
                    that.loading = false;
                });
            }
        }

    },
};
</script>
<style scoped>
.login{
    /* background-image: linear-gradient(to right,#fbc2eb,#a6c1ee); */
    background-color: rgb(200,29,21);
    height: 100vh;width: 100vw;display:flex;justify-content: center;
    align-items:center;
}
.login .login_center{padding:50px 20px; width: 400px;border-radius: 15px;background-color: #fff;}
.login .title{font-weight:600;text-align:center;line-height: 20px;font-size:16px;}
.login .input-item{ display: block;width: 100%;margin-bottom: 20px;border: none;padding: 10px;border-bottom: 2px solid rgb(128,125,125);font-size: 15px;outline: none;}
.login .wrapbtn{display:flex;}
.login .wrapbtn .loginbtn{width:100%; justify-content: center;padding: 5px;margin-top: 20px; background-image: linear-gradient(to right,#fbc2eb,#a6c1ee);color: #fff;}
.login .signup{text-align: right;margin-top: 20px;cursor: pointer;}
.login .logo{width: 100%;height: 100%;}
.login .logo img{width: 100%;height: 100%;}
</style>